import Vue from 'vue'
import Vuex from 'vuex'
import catalog from '@/store/modules/catalog'
import products from '@/store/modules/products'
import auth from '@/store/modules/auth'
import clients from '@/store/modules/clients'
import errors from '@/store/modules/errors'
import lang from '@/store/modules/lang'
import orders from '@/store/modules/orders'
import system from '@/store/modules/system'
import content from '@/store/modules/content'
import analogues from '@/store/modules/analogues'
import statistics from '@/store/modules/statistics'
import notifications from '@/store/modules/notifications'
import properties from '@/store/modules/properties'
import propertyTypes from '@/store/modules/property_types'
import propertyValues from '@/store/modules/property_values'
import dropCatalog from '@/store/modules/dropCatalog'
import cylinderCatalog from '@/store/modules/cylinderCatalog'
import inetOrders from '@/store/modules/inet_orders'
import novaPoshta from '@/store/modules/nova_poshta'
import search from '@/store/modules/search'
import novaPoshtaSenders from '@/store/modules/novaPoshtaSenders'
import systemEvents from '@/store/modules/systemEvents'
import rozetkaStores from '@/store/modules/rozetkaStores'
import dropOrders from '@/store/modules/dropOrders'
import dropClients from '@/store/modules/dropClients'
import dropWithdraws from '@/store/modules/dropWithdraws'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    catalog,
    products,
    clients,
    errors,
    lang,
    system,
    orders,
    content,
    analogues,
    statistics,
    notifications,
    properties,
    propertyTypes,
    propertyValues,
    dropCatalog,
    cylinderCatalog,
    inetOrders,
    novaPoshta,
    search,
    novaPoshtaSenders,
    systemEvents,
    rozetkaStores,
    dropOrders,
    dropClients,
    dropWithdraws
  }
})
